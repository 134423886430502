export class Engine {
  constructor({
    APP,
    USER,
    IDENTITY,
    BROWSER_ID,
    Vue,
    Vuex,
    STORE,
    axios,
    platform = 'desktop',
    drivers = [],
  }) {
    this.APP = APP;
    this.USER = USER;
    this.IDENTITY = IDENTITY;
    this.BROWSER_ID = BROWSER_ID;
    this.platform = platform;
    this.Vue = Vue;
    this.Vuex = Vuex;
    this.STORE = STORE;
    this.axios = axios;
    this.drivers = [...drivers];

    this.runDrivers();
  }

  static boot(...args) {
    if (!Engine.instance) {
      Engine.instance = new Engine(...args);
    }
  }

  runDrivers() {
    this.drivers.forEach(async driver => {
      await driver.run({
        platform: this.platform,
        API: window.API,
        APP: this.APP,
        USER: this.USER,
        IDENTITY: this.IDENTITY,
        BROWSER_ID: this.BROWSER_ID,
        STORE: this.STORE,
        Vue: this.Vue,
        Vuex: this.Vuex,
        axios: this.axios,
      });
    });
  }
}

export default {
  Engine,
};
