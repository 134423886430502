import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { Engine } from './engine';

Vue.use(Vuex);
Vue.config.ignoredElements = ['fragment-loader', 'nuxt-link'];

// globals
window.Vue = Vue;
window.Vuex = Vuex;
window.STORE = new Vuex.Store();
window.axios = axios;
window.ENGINE = Engine;
